<template>
  <div class="header">
    <h1>{{ this.$t("mypage.account") }}</h1>
    <ul class="path">
      <li>
        <router-link to="/mypage">{{ this.$t("mypage.mypage") }}</router-link>
      </li>
      <li class="current">
        <router-link to="/mypage/account">{{
          this.$t("mypage.account")
        }}</router-link>
      </li>
    </ul>
  </div>
  <ul class="form">
    <li class="account_type" v-if="this.is_sns_joined">
      <label>{{ this.$t("mypage.account_type") }}</label>
      <img src="../../assets/img/icon_google_logo.svg" />
    </li>
    <li>
      <label>{{ this.$t("mypage.account_name") }}</label>
      <input type="text" v-model="this.name" :readonly="this.is_sns_joined" />
    </li>
    <li>
      <label>{{ this.$t("mypage.account_email") }}</label>
      <input type="email" v-model="this.email" :readonly="this.is_sns_joined" />
    </li>
    <li>
      <label>{{ this.$t("mypage.account_mobile") }}</label>
      <input type="text" v-model="this.tel" />
    </li>
    <li>
      <label>{{ this.$t("mypage.account_sex") }}</label>
      <select v-model="this.sex">
        <option value="">{{ this.$t("mypage.account_sex") }}</option>
        <option value="M">{{ this.$t("mypage.account_sex_m") }}</option>
        <option value="F">{{ this.$t("mypage.account_sex_f") }}</option>
      </select>
    </li>
    <li>
      <label>{{ this.$t("mypage.account_birthdate") }}</label>
      <select class="date" v-model="this.birth_year">
        <option value="">{{ this.$t("mypage.account_year") }}</option>
        <option v-for="n in 120" :key="n" :value="new Date().getFullYear() - n">
          {{ new Date().getFullYear() - n }}
        </option>
      </select>
      <select class="date" v-model="this.birth_month">
        <option value="">{{ this.$t("mypage.account_month") }}</option>
        <option v-for="n in 12" :key="n" :value="n">
          {{ n }}
        </option>
      </select>
      <select class="date" v-model="this.birth_day">
        <option value="">{{ this.$t("mypage.account_day") }}</option>
        <option v-for="n in 31" :key="n" :value="n">
          {{ n }}
        </option>
      </select>
    </li>
    <li v-if="!this.is_sns_joined">
      <label>{{ this.$t("mypage.account_password") }}</label>
      <button class="button small red" @click="this.open_password_popup">
        {{ this.$t("mypage.change") }}
      </button>
    </li>
  </ul>

  <div class="button_wrap">
    <button class="button outline middle" @click="this.$router.push('/mypage')">
      {{ this.$t("common.cancel") }}
    </button>
    <button class="button middle" @click="this.click_save_user_data">
      {{ this.$t("mypage.save") }}
    </button>
  </div>

  <Transition name="popup">
    <div
      class="overlay"
      v-if="this.show_password_popup"
      @click="this.close_password_popup"
    ></div>
  </Transition>
  <Transition name="popup">
    <div
      class="password_change_wrap popup_wrap"
      v-if="this.show_password_popup"
    >
      <div class="header">
        {{ this.$t("mypage.password_change") }}
        <div class="close" @click="this.close_password_popup"></div>
      </div>
      <div class="body">
        <div class="box">
          <input
            type="password"
            v-model="this.old_password"
            :placeholder="this.$t('mypage.old_password')"
          />
        </div>
        <div class="box">
          <input
            type="password"
            v-model="this.new_password"
            :placeholder="this.$t('mypage.new_password')"
          />
        </div>
        <div class="box">
          <input
            type="password"
            v-model="this.new_password_repeat"
            :placeholder="this.$t('mypage.new_password_repeat')"
          />
        </div>
      </div>
      <div class="footer">
        <button class="button small" @click="this.click_change_password">
          {{ this.$t("mypage.change") }}
        </button>
      </div>
    </div>
  </Transition>

  <Spinner v-show="processing" />
</template>

<script>
export default {
  name: "Account",
  metaInfo() {
    return {
      title: this.$t("meta.title") + " :: " + this.$t("meta.account"),
      og: {
        title: this.$t("meta.title") + " :: " + this.$t("meta.account"),
      },
    };
  },
  data: function () {
    return {
      process_queue: [],
      show_password_popup: false,
      name: "",
      email: "",
      tel: "",
      sex: "",
      birth_year: "",
      birth_month: "",
      birth_day: "",
      sns_type: "",

      old_password: "",
      new_password: "",
      new_password_repeat: "",
    };
  },
  watch: {
    // 전화번호 숫자만 입력받기 (처음 시작에는 + 문자 가능)
    tel: function () {
      let prefix = "";
      if (this.tel.startsWith("+")) prefix = "+";
      this.tel = prefix + this.tel.replace(/[^0-9]/g, "");
    },
  },
  computed: {
    processing() {
      if (this.process_queue.length <= 0) return false;
      else return true;
    },
    // SNS 가입 여부
    is_sns_joined() {
      if (
        typeof this.sns_type == "undefined" ||
        this.sns_type == null ||
        this.sns_type == ""
      ) {
        return false;
      }

      return true;
    },
  },
  mounted() {
    this.init_data();
  },
  methods: {
    init_data: function () {
      if (!this.storage.is_logged_in()) return;
      this.load_user_data();
    },

    load_user_data: function () {
      if (!this.storage.is_logged_in()) return;

      let self = this;
      self.process_queue.push(1);

      // 사용자 정보 가져오기
      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/auth/get",
          {
            user_id: self.storage.get_user_id(),
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          let data = response.data;

          if (data.code == 200 && data.result == "OK") {
            self.name = data.data.user_name;
            self.email = data.data.user_email;
            self.tel = data.data.user_mobile;
            self.sex = data.data.user_sex;

            if (data.data.user_birthdate) {
              let parsed_date = self.common.date_parse(
                data.data.user_birthdate
              );
              self.birth_year = parsed_date.getFullYear().toString();
              self.birth_month = (parsed_date.getMonth() + 1).toString();
              self.birth_day = parsed_date.getDate().toString();
            }

            if (
              typeof data.data.sns_type != "undefined" &&
              data.data.sns_type != null &&
              data.data.sns_type != "null"
            ) {
              self.sns_type = data.data.sns_type;
            }
          } else if (data.code > 200) {
            self.$swal
              .fire({ text: self.$t("common.error_occur"), icon: "error" })
              .then((result) => {
                result;
                self.$router.back();
              });
          }
        })
        .finally(function () {
          self.process_queue.pop();
        }); //-- axios
    }, //-- load_user_data

    open_password_popup: function () {
      this.old_password = "";
      this.new_password = "";
      this.new_password_repeat = "";
      this.show_password_popup = true;
    },
    close_password_popup: function () {
      this.old_password = "";
      this.new_password = "";
      this.new_password_repeat = "";
      this.show_password_popup = false;
    },

    click_change_password: function () {
      if (!this.storage.is_logged_in()) return;

      let self = this;

      if (this.old_password.trim() == "") {
        self.$swal.fire({
          text: this.$t("mypage.error_no_old_password"),
          icon: "error",
        });
        return;
      }

      if (this.new_password.trim() == "") {
        self.$swal.fire({
          text: this.$t("mypage.error_no_new_password"),
          icon: "error",
        });
        return;
      }

      if (this.new_password_repeat.trim() == "") {
        self.$swal.fire({
          text: this.$t("mypage.error_no_new_password_repeat"),
          icon: "error",
        });
        return;
      }

      if (this.old_password == this.new_password) {
        self.$swal.fire({
          text: this.$t("mypage.error_old_new_password_same"),
          icon: "error",
        });
        return;
      }

      if (this.new_password != this.new_password_repeat) {
        self.$swal.fire({
          text: this.$t("mypage.error_password_not_match"),
          icon: "error",
        });
        return;
      }

      self.$swal
        .fire({
          text: this.$t("mypage.confirm_change_password"),
          icon: "question",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            self.request_change_password();
          } //-- isConfirmed
        }); //-- swal then
    }, //-- click_change_password

    request_change_password: function () {
      if (!this.storage.is_logged_in()) return;

      let self = this;
      self.process_queue.push(1);

      // 비밀번호 변경
      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/auth/password/change",
          {
            user_id: self.storage.get_user_id(),
            old_password: self.old_password,
            new_password: self.new_password,
            new_password_repeat: self.new_password_repeat,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          let data = response.data;
          let result = data.result;

          if (result == "ERR_NO_LOGIN" || result == "ERR_USER_ID_NOT_MATCH") {
            self.$swal.fire({
              text: self.$t("common.error_invalid_access"),
              icon: "error",
            });
            return;
          } else if (result == "ERR_PASSWORD_NOT_MATCH") {
            self.$swal.fire({
              text: self.$t("mypage.error_password_not_match"),
              icon: "error",
            });
            return;
          } else if (result == "ERR_SHORT_PASSWORD") {
            self.$swal.fire({
              text: self.$t("mypage.error_short_password"),
              icon: "error",
            });
            return;
          } else if (result == "ERR_NO_USER_DATA") {
            self.$swal.fire({
              text: self.$t("mypage.error_no_user_data"),
              icon: "error",
            });
            return;
          } else if (result == "ERR_WRONG_OLD_PASSWORD") {
            self.$swal.fire({
              text: self.$t("mypage.error_wrong_old_password"),
              icon: "error",
            });
            return;
          }

          if (data.code == 200 && data.result == "OK") {
            // 성공
            self.$swal
              .fire({
                text: self.$t("mypage.password_changed"),
                icon: "success",
              })
              .then(self.close_password_popup);
            return;
          } else {
            self.$swal.fire({
              text: self.$t("common.error_occur"),
              icon: "error",
            });
            return;
          }
        })
        .finally(function () {
          self.process_queue.pop();
        }); //-- axios
    }, //-- request_change_password

    click_save_user_data: function () {
      if (!this.storage.is_logged_in()) return;

      let self = this;

      if (self.name.trim() == "") {
        self.$swal.fire({
          text: self.$t("mypage.error_enter_name"),
          icon: "error",
        });
        return;
      }

      if (self.email.trim() == "") {
        self.$swal.fire({
          text: self.$t("mypage.error_enter_email"),
          icon: "error",
        });
        return;
      }

      if (self.tel.trim() == "") {
        self.$swal.fire({
          text: self.$t("mypage.error_enter_mobile"),
          icon: "error",
        });
        return;
      }

      if (self.sex.trim() == "") {
        self.$swal.fire({
          text: self.$t("mypage.error_select_sex"),
          icon: "error",
        });
        return;
      }

      if (
        self.birth_year.trim() == "" ||
        self.birth_month.trim() == "" ||
        self.birth_day.trim() == ""
      ) {
        self.$swal.fire({
          text: self.$t("mypage.error_select_birthdate"),
          icon: "error",
        });
        return;
      }

      self.$swal
        .fire({
          text: this.$t("mypage.confirm_save"),
          icon: "question",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            self.save_user_data();
          } //-- isConfirmed
        }); //-- swal then
    }, //-- click_save_user_data

    save_user_data: function () {
      // 로그인되어 있지 않으면 진행 안함
      if (!this.storage.is_logged_in()) {
        return;
      }

      let self = this;

      // 생년월일 정제
      let user_birthdate = "";
      try {
        let obj = this.common.date_parse(
          this.birth_year +
            "-" +
            this.birth_month.padStart(2, "0") +
            "-" +
            this.birth_day.padStart(2, "0")
        );
        user_birthdate =
          obj.getFullYear().toString() +
          "-" +
          (obj.getMonth() + 1).toString().padStart(2, "0") +
          "-" +
          obj.getDate().toString().padStart(2, "0");
      } catch {
        user_birthdate = "";
      }

      self.process_queue.push(1);

      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/auth/edit",
          {
            user_id: self.storage.get_user_id(),
            user_name: self.name,
            user_email: self.email,
            user_sex: self.sex,
            user_mobile: self.tel,
            user_birthdate: user_birthdate,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          let data = response.data;

          if (data.code == 200 && data.result == "OK") {
            self.$swal.fire({
              text: self.$t("common.saved"),
              icon: "success",
            });
          } else if (data.code > 200) {
            self.$swal.fire({
              text: self.$t("common.error_occur"),
              icon: "error",
            });
          }

          return true;
        })
        .finally(function () {
          self.process_queue.pop();
        }); //-- axios
    }, //-- save_user_data_server
  },
};
</script>

<style scoped>
.form {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.form > li {
  margin: 0;
  padding: 0.4rem 0;

  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.form > li > label {
  display: block;
  width: 120px;
  font-size: 1.1rem;
  font-weight: 700;
}
.form > li > input {
  flex-grow: 1;
  margin-right: 1rem;
  font-size: 1.1rem;
  font-weight: 400;
}
.form > li > select.date {
  margin-right: 0.4rem;
}
.form > li.account_type > img {
  display: block;
  height: 1.4rem;
  margin: 0.4rem 0;
}

.password_change_wrap > .body > .box {
  margin: 0 0 1rem 0;
}
.password_change_wrap > .body > .box > input[type="password"] {
  width: 100%;
}

.button_wrap {
  margin: 2rem 0 0 0;
  text-align: center;
}
.button_wrap > button {
  margin: 0 0.4rem;
}
</style>
